import Finity from 'finity';

$(document).on('turbolinks:load', function startUi() {
  $('[data-finity-allocation_key_copying]').each((index, button) => {
    $(button).on('click', (e) => {
      e.preventDefault();

      const modalContent = $('#modal-form-content').html();
      Finity.dialog.message(modalContent, "Select Service & Provider ARE");

      initializeSelect2('#serviceSelect');
      initializeSelect2('#providerSelect');

      $('#serviceSelect').on('change', function() {
        updateProviderOptions($(this).val());
      });

      $('#finity-modal .modal-footer').not('.keep-footer').remove();
      autoSelectFields();
    });
  });
});

const updateProviderOptions = (service) => {
  Finity.fetch(`/key_generator?mode=get_allowed_key_types&portfolio_no=${service}`, { method: 'GET' })
    .then((response) => {
      const providerSelect = $('#providerSelect');
      providerSelect.empty();

      if (response.ares === null) {
        providerSelect.append("<option value='0'>N/A - apply at service level</option>");
      } else {
        response.ares.forEach((are) => {
          providerSelect.append(new Option(are[0], are[0]));
        });
      }

      initializeSelect2('#providerSelect');
      updateAllocationKeyName();
    });
};

const initializeSelect2 = (selector) => {
  const $selector = $(selector);
  const $form = $('#providerServiceForm');
  const $hiddenInput = $('<input type="hidden" name="portfolio_no" value="">');

  $selector.select2({
    placeholder: { id: '' },
    allowClear: true,
    width: 'resolve',
    dropdownParent: $('#finity-modal')
  }).on('select2:unselecting', (e) => {
    $selector.val(null).trigger('change');
    if (!$form.find('input[name="portfolio_no"]').length) {
      $form.append($hiddenInput);
    }
    updateAllocationKeyName();
    e.preventDefault();
  }).on('select2:select', () => {
    $form.find('input[name="portfolio_no"]').val($selector.val());
    updateAllocationKeyName();
  });
};

const updateAllocationKeyName = () => {
  const service = $('#serviceSelect').val();
  const provider = $('#providerSelect').val();
  $('#allocationKeyName').val(service ? (provider ? `${service} ${provider}` : service) : '');
};

const autoSelectFields = () => {
  updateProviderOptions($('#serviceSelect').val());
  updateAllocationKeyName();
};
