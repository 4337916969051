//Livewires are small dialogs that have their HTML downloaded and input directly into the modal dialog. They can then be submit back to the controller.
import Finity from 'finity'

$(document).on('turbolinks:load', function startUi () {

  //Subservice creation dialog.
  $('[data-finity-dialog=create-subservice]').each ( function (x) {
    $(this).on('click', function (e) {
      e.preventDefault();

      Finity.dialog.livewire("/subservices/new", { 'subservice[service_id]': $(this).data('finity-dialog-service-id')});
    });
  });

  //Cost center unparking dialog.
  $('[data-finity-dialog=unpark-cost-center]').each ( function (x) {
    $(this).on('click', function(e) {
      e.preventDefault();

      var are_code = $(this).data('finity-dialog-are');
      var cost_center_code = $(this).data('finity-dialog-cost-center');

      Finity.dialog.livewire("/cost_centers/unpark")
      .then(function(modal) {

        //Preselect the ARE and cost center.
        $('select option',modal).map(function(y, opt) {
          $(opt).prop('selected', $(opt).text().substr(0,4) == are_code);
        });

        $('[name=code]',modal).prop('value',cost_center_code);

      });
    });
  });

});
