// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// Libaries
import 'bootstrap'
import 'xlsx'
import 'select2';
import 'select2/dist/css/select2.min.css';

//Application code
import 'ui'
import 'tableau_embed'
import 'masterdata_upload'
import 'masterdata_download'
import 'livewires'
import 'key_generator'
import 'key_generator_adhoc_form'
import 'target_groups'
import 'allocation_key_copying.js'

Rails.start()
Turbolinks.start()
ActiveStorage.start()
