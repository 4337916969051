//Masterdata upload dialog.
import Finity from 'finity'
import XLSX from 'xlsx'
import * as Bootstrap from 'bootstrap'

$(document).on('turbolinks:load', function startUi () {

  //Look for the key generator form. If not found, do nothing else.
  var form = $('#keyWizard')[0];
  if ( form == undefined )
    return;

  var highlight_all = function(true_or_false) {
    return function(e) {
      e.preventDefault();
      var prefix = $(e.currentTarget).data('keygen-select-all');
      if (prefix == undefined) {
        prefix = $(e.currentTarget).data('keygen-select-none');
      }
      $('input[type=checkbox]').each(function(index) {
        if ($(this).prop("name").indexOf(prefix) != -1) {
          $(this).prop("checked", true_or_false);
        }
      })
      update_checkbox_counters();
    }
  }

  $('[data-keygen-select-all]').on('click', highlight_all(true));
  $('[data-keygen-select-none]').on('click', highlight_all(false));

  var default_name = function() {
    var are_text = ""
    if ($('#step2 select[name=provider_are]', form).val() != 0) {
      are_text = " " + $('#step2 select[name=provider_are] option:selected', form).text()
    }
    return (selected_service() + " FY " + selected_fy() + are_text)
  }

  var selected_fy = function () {
    return $('#step1 input:checked').val();
  };

  var selected_service = function () {
    var value = $('#step2 select[name=portfolio_no]').val();

    //The dummy entry has a value of 0, and is not valid.
    if ( value == 0 )
      return undefined;

    return value;
  };

  var selected_are = function () {
    return $('#step2 select[name=provider_are]').val();
  }

  var key_type = function () {
    return $('#step3 input[name=key_type]:checked').val();
  };
  
  var name = function () {
    return $('input[name=name]').val();
  };

  var description = function () {
    return $('input[name=description]').val();
  };

  var spinner = function (text) {
    if ( text == undefined ) {
      $('#keygen_spinner').css('display','none');
    }
    else {
      $('#keygen_spinner span').html(text);
      $('#keygen_spinner').css('display','block');
    }
  };
  spinner();

  //Step four requires a manual button push to be considered valid.
  var stepFourConfirmed = false;
  var stepFiveConfirmed = false;

  var reset_steps = [];
  var update_step = function (step) {

    //Reset any data in the steps after this point.
    for (var x = step-1; x < reset_steps.length; x++ ) {
      reset_steps[x]();
    }

    //Update the buttons to disable future steps.
    var buttons = $('.accordion-button',form);
    buttons.each( function (i,cur) {
      $(cur).prop('disabled',$(cur).data('step') > step);
      if ((i+1) == step && !$($(cur).data('bs-target')).hasClass('show')) {
        $(cur).click();
      }
    });

    return step;
  };
  update_step (1);

  //Nothing to reset in first step (FY).
  reset_steps.push( function () { } );
  reset_steps.push( function stepTwo () {
    $('#step2 select').val(0);
    $('#step2 select[name=provider_are]').empty();
  });
  reset_steps.push ( function stepThree() {
    $('#step3 input[name=key_type]').prop('checked', false);
  });
  reset_steps.push ( function stepFour () {
    stepFourConfirmed = false;
  });
  reset_steps.push ( function stepFive () {
    stepFiveConfirmed = false;
  });

  //First step - when a FY is selected, progress.
  $('#step1 input', form).on('change', function (e) {
      return update_step(2);
  });

  $('#step1 input:last', form).prop('checked', true);
  update_step(2);

  $('#step2 a[data-next-step]', form).on('click', function (e) {
    e.preventDefault ();
    if (selected_are() == undefined || selected_service() == undefined) {
      Finity.dialog.message("Select both a service and a provider ARE to continue.","Missing Information");
      return;
    }
    return update_step(3);
  });

  //Second step - when a service is selected, check if we can allow its use or not.
  //Update 27/03/24: fill the ARE list as well.
  $('#step2 select[name=portfolio_no]', form).on('change', function (e) {

    spinner("Checking for allowable keys/AREs");
    Finity.fetch('/key_generator?mode=get_allowed_key_types&portfolio_no=' + selected_service() + '&fy=' + selected_fy(), { method: 'GET' })
    .then(function (response) {
      spinner();

      if (response.actuals) {
        $('#actuals-option').css('display', 'block');
        $('#actuals-no-option').css('display', 'none');
      } else {
        $('#actuals-option').css('display', 'none');
        $('#actuals-no-option').css('display', 'block');
      }

      if (response.composite) {
        $('#composite-option').css('display', 'block');
        $('#composite-no-option').css('display', 'none');
      } else {
        $('#composite-option').css('display', 'none');
        $('#composite-no-option').css('display', 'block');
      }

      var are_selector = $('#step2 select[name=provider_are]', form);
      are_selector.empty();
      if ( response.ares === null ) {
        are_selector.append("<option value='0'>N/A - apply at service level</option>")
      }
      else {
        for ( let x = 0; x < response.ares.length; x++ )
        {
          var current = response.ares[x];
          var newEl = $('<option>' + current[0] + '</option>');
          newEl.val(current[1]);
          are_selector.append(newEl);
        }
      }

      $('#step5 input[name=name]').val (default_name());
    })

  });

  //Change the name when the ARE is changed.
  $('#step2 select[name=provider_are]', form).on('change', function (e) {
      $('#step5 input[name=name]').val (default_name());
  });

  //Third step - when a key type is selected, only show the relevant customisations.
  $('#step3 input', form).on('change', function (e) {

    //Show the right customisations in the next step.
    $('.row[data-for]').css('display','none');
    $('.row[data-for=' + key_type() + ']').css('display','flex');

    if(key_type() == 'composite') {
      spinner("Fetching existing keys");
      Finity.fetch("/key_generator?mode=get_composite_form&portfolio_no=" + selected_service() + "&fy=" + selected_fy(), { method: 'GET' })
      .then(function (response) {
        return response.text();
      })
      .then(function (content) {
        spinner();
        $('#step4 [data-for=composite]').html(content);
        update_step(4);
      });
      return;
    }

    update_step(4);
  });

  $('#step3 .keygen-type-selections .col', form).on('click', function (e) {
    $('input[type=radio]', this).prop('checked',true);

    if ($('input[type=radio]', this).length != 0)
      $('#step3 input', form).trigger('change');

    if (key_type() == 'standalone') {
      Finity.dialog.message("This will create a non-standard key, outside of policy. Please ensure this is aligned with your Financial Manager. You'll need to add manual volumes once you've created it.","Non-Standard Key");
    }
  });

  //Fourth step - just click through, but validate special for certain types.
  $('#step4 a[data-next-step]', form).on('click', function (e) {
    e.preventDefault ();

    switch (key_type()) {
      case 'subset':
        var ares = $('#ares input:checked').length;
        var business_units = $('#business_units input:checked').length;
        var countries = $('#countries input:checked').length;
        var divisions = $('#divisions input:checked').length;

        if ( ( ares + countries ) == 0 || ( divisions + business_units ) == 0 ) {
          Finity.dialog.message("You need to choose at least one country or ARE, and one division or business unit to continue.","No Subset Chosen");
          return;
        }

        var allAres = $('#ares input').length == $('#ares input:checked').length;
        var allBusinessUnits = $('#business_units input').length == $('#business_units input:checked').length;
        var allCountries = $('#countries input').length == $('#countries input:checked').length;
        var allDivisions = $('#divisions input').length == $('#divisions input:checked').length;

        if (allAres && allBusinessUnits && allCountries && allDivisions) {
          Finity.dialog.message("You have selected all combinations. Please choose a 'Global' key instead of a 'Subset' in Step 3.","Global Subset Chosen");
          return;
        }
        if ((allAres || allCountries) && (allDivisions || allBusinessUnits)) {
          Finity.dialog.message("You have selected all combinations. Please choose a 'Global' key instead of a 'Subset' in Step 3.","Global Subset Chosen");
          return;
        }
      break;

      case 'user_list':
        var users = $('#step4 input[type=hidden]').val();

        if ( users == undefined || users.length == 0 ) {
          Finity.dialog.message("You need to have at least one user uploaded to continue.","No Users Chosen");
          return;
        }

      break;
    }

    update_step(5);
  });

  // Manage the GID normalization.
  var onUserParsed = function () {
    var no_valid = uploader.data().valid.length;

    $('#step4 input[type=hidden]').remove();
    for ( let x = 0; x < no_valid; x++ ) {
      var newItem = $('<input type="hidden" name="gids[]">');
      newItem.val(uploader.data().valid[x].gid_id);

      // If weightings were not provided, assume 1.
      var newWeight = $('<input type="hidden" name="weighting[]">');
      if ( uploader.data().valid[x].weighting ) {
        newWeight.val( uploader.data().valid[x].weighting )
      }
      else {
        newWeight.val ( 1 );
      }

      $('#step4').append(newItem);
      $('#step4').append(newWeight);
    }
    
    $('#step4 [data-finity-upload-status]').html("<strong class='text-success'>Ready:</strong> " + uploader.data().valid.length + " lines");
  };

  var uploader = Finity.uploader($('#step4', form),'parsers.keygen_userlist',onUserParsed);
  $('#step4 input[type=file]',form).on('change', function (e) {
    var file = e.target.files[0];
    uploader.parse(file);
  });

  //Subset: checkbox handling for the 4 types.
  var update_checkbox_counters = function (e) {
    var countries = $('#countries input:checked').length;
    var ares = $('#ares input:checked').length;

    var divisions = $('#divisions input:checked').length;
    var business_units = $('#business_units input:checked').length;

    if (countries>0) {
      $('#ares input').attr('checked', false);
      $('#ares_selector').css('display','none');
    }
    else {
      $('#ares_selector').css('display','block');
    }
      
    $('#ares input').attr('disabled', countries > 0 );

    if (ares>0)
    {
      $('#countries input').attr('checked', false);
      $('#countries_selector').css('display','none');
    }
    else {
      $('#countries_selector').css('display','block');
    }
      
    $('#countries input').attr('disabled', ares > 0 );

    if (divisions>0) {
      $('#business_units input').attr('checked', false);
      $('#business_units_selector').css('display','none');
    }
    else {
      $('#business_units_selector').css('display','block');
    }
      
    $('#business_units input').attr('disabled', divisions > 0);

    if (business_units>0) {
      $('#divisions input').attr('checked', false);
      $('#divisions_selector').css('display','none');
    }
    else {
      $('#divisions_selector').css('display','block');
    }
      
    $('#divisions input').attr('disabled', business_units > 0);

    //Update the counters.
    $('#are-counter').html( (ares == 0) ? '' : ares );
    $('#country-counter').html( (countries == 0) ? '' : countries );
    $('#business-unit-counter').html( (business_units == 0) ? '' : business_units );
    $('#division-counter').html( (divisions == 0) ? '' : divisions );

  }
  $('#step4 input', form).on('change', update_checkbox_counters);

  $('#step5 a[data-next-step]', form).on('click', function (e) {
    e.preventDefault();
    if (name().length == 0 || description().length == 0) {
      Finity.dialog.message("A name and description must be chosen for the key.","Invalid Name/Description");
      return;
    }
    stepFiveConfirmed = true;

    update_step(6);
  });

  $(form).on('submit', function (e) {
    spinner("Creating key");
    $('#create-key-submit').prop('disabled', true)
  });

  $(form).on('ajax:success', function (e) {
    const [_data, _status, xhr] = e.detail;
    const response = JSON.parse(xhr.responseText);
    $('#create-key-submit').prop('disabled', false)
    if ( response.success == false ) {
      Finity.dialog.message(response.error, "Error Generating Key");
      spinner();
    }
    else {
      spinner("Loading newly created key...");
      Turbolinks.visit(response.redirect_to);
    }
  });

  // Define the search functionality
  $(document).ready(function() {
    $('.select2-dropdown').select2({
      placeholder: {
        id: ''
      },
      allowClear: true,
      width: 'resolve'
    }).on('select2:unselecting', function(e) {
      $(this).val(null).trigger('change');
      e.preventDefault();
    });
  });

});
