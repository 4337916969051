import * as Bootstrap from 'bootstrap'

let Finity = {};

Finity.CSRFToken = function () {
  return $('meta[name=csrf-token]').attr('content')
};

//Add the cross-realm scripting safety token to all AJAX requests. Returns the usual fetch promise.
Finity.fetch = function (path, args) {

  var combinedArgs = Object.assign(args,
  {
    headers: {
      'X-CSRF-Token': Finity.CSRFToken(),
      'Content-Type': 'application/json; charset=utf-8'
    }
  });

  return fetch(path, combinedArgs)
  .then(function (response) {

      if (response.status != 200) {
        throw response;
        return;
      }

      if (response.headers.get('Content-Type').includes('text/html')) {
        return response;
      }

      if (response.headers.get('Content-Type').includes('application/json')) {
        return response.json();
      }

      throw 'Unknown response format';
    });

};

//Various helper dialogs for use throughout the application.
Finity.dialog = {};

var makeModal = function(pBody, pTitle, buttons) {
  var domModal = $('#finity-modal');
  var bsModal = new Bootstrap.Modal(domModal);

  //Ensure any previous event handlers are cleaned up.
  domModal.off('shown.bs.modal');

  var title = $('#finity-modal .modal-title');
  var body = $('#finity-modal .modal-body');
  var footer = $('#finity-modal .modal-footer');

  title.html(pTitle);
  body.html("<p>" + pBody + "</p>");
  footer.empty();

  var returnButtons = [];
  for ( var x = 0; x < buttons.length; x++ ) {
    var currentButton = buttons[x];
    var buttonDOM = $('<button type="button" class="btn"></button>');
    buttonDOM.addClass(currentButton.class);
    buttonDOM.html(currentButton.text);
    footer.append(buttonDOM);
    returnButtons[x] = buttonDOM;

    buttonDOM.on('click', function createHandler(cur) {
      return function handleModalButtonEvent(e) {
        bsModal.hide();
        if ( cur.clicked != undefined )
          cur.clicked (e);
      }
    }(currentButton) );
  }
  return { domModal: domModal, bsModal: bsModal, title: title, body: body, footer: footer, buttons: returnButtons }
};

Finity.dialog.message = function (pBody, pTitle, pButtonText = "OK", onConfirm = undefined) {
  var modal = makeModal (pBody, pTitle, [{text: pButtonText, class:'btn-primary', clicked: onConfirm}]);
  modal.bsModal.show();
  return modal;
};

Finity.dialog.confirm = function (pBody, pTitle, pConfirmText = "Confirm", onConfirm, pCancelText = "Cancel", onCancel = undefined) {
  var modal = makeModal(pBody, pTitle, [{text: pConfirmText, class:'btn-primary', clicked: onConfirm}, {text: pCancelText, class:'btn-outline-danger', clicked: onCancel}]);
  modal.bsModal.show();
  return modal;
};

Finity.dialog.textInput = function (pBody, pTitle, pConfirmText = "Confirm", onConfirm, pCancelText = "Cancel", onCancel = undefined) {
  //Pass through the value of the input to the event handler.
  var addInputToConfirm = function (e) {
    onConfirm(e,$('input',modal.domModal)[0].value);
  };

  var modal = makeModal(pBody, pTitle, [{text: pConfirmText, class:'btn-primary', clicked:addInputToConfirm}, {text: pCancelText, class:'btn-outline-danger', clicked: onCancel}]);

  var inputDOM = $('<input type="text" class="form-control" name="modal-text-input" class="ms-3"></input>');
  modal.body.append(inputDOM);

  //The submit button is disabled by default until the user inputs a value.
  modal.buttons[0].prop('disabled',true);

  //When the text is changed, so long as it's non-zero, enable the button.
  var checkButtonState = function(e) {
    modal.buttons[0].prop('disabled',(inputDOM.val().length == 0));
  };
  inputDOM.on('input', checkButtonState );

  //Always focus the text input for usability.
  modal.domModal.on('shown.bs.modal', function () {
    inputDOM.focus();
  });
  modal.bsModal.show();

  return modal;
};

Finity.dialog.fileInput = function (pBody, pTitle, pConfirmText, onConfirm) {
  var modal = makeModal(pBody,pTitle, [{text: pConfirmText, class:'btn-primary', clicked: onConfirm}, {text: "Cancel", class:'btn-outline-danger'}]);

  var fileDOM = $('<input class="form-control" type="file"/>');
  modal.body.append(fileDOM);

  modal.bsModal.show();

  return modal;
};

Finity.dialog.livewire = function (controller, extraFields = {}) {

  return Finity.fetch(controller, { method: 'GET' })
  .then(function (response) {
    return response.text().then(function(content) {
      $('#finity-modal .modal-content').html(content);

      //Add this page URL as a hidden element to make sure we come back here.
      if (extraFields.redirect_to === undefined) {
        extraFields.redirect_to = window.location.href;
      }

      for (const property in extraFields) {
        var extra_field = $('<input type="hidden" name="' + property + '"/>');
        extra_field.prop('value', extraFields[property]);
        $('#finity-modal form').append(extra_field);
      }

      var bsModal = new Bootstrap.Modal($('#finity-modal'));
      bsModal.show();
      return $('#finity-modal');
    })
  })
  .catch(function (error) {
    Finity.dialog.message("Unable to create dialog.","Internal error");
  });

};

//Creates a new Uploader object to manage Excel file parsing. It must be passed the root element to create the component.
Finity.uploader = function (rootElement,parserType,onParse) {
  var returnUploader = {};

  //The number of individual errors to show.
  const MAX_ERRORS_TO_SHOW = 20;

  //The number of line items to show, per error, before just consolidating it.
  const MAX_LINE_NUMBERS_TO_SHOW = 10;

  //The number of lines to send per request, as a maximum.
  const UPLOAD_SEGMENT_SIZE = 3000;

	//The parallel parser worker thread.
	var parser = null;

	//The processed data we will send to the server.
	var data = { all: [], valid: [], invalid: [] };

  //The progress bar - only shown if DOM element is present.
	var progress = function (selector, startColour = 'bg-secondary') {
		var progressHolder = selector;
		var progressBar = $('.progress-bar',selector);
		var currentClass = startColour;

		progressHolder.css('display','none');

		var set = function ( pct = -1, text = '%', colour = 'bg-secondary' ) {
      if ( progressHolder == undefined )
        return;

			if ( pct == -1 ) {
				progressHolder.css('display','none');
				return;
			}

			progressHolder.css('display','flex');
			progressBar.css('width',pct*100 + '%');
			progressBar.attr('aria-valuenow',pct*100);

			if ( text == '%' ) {
				progressBar.html(Math.floor(pct*100) + '%')
			} else {
				progressBar.html(text);
			}

			progressBar.removeClass(currentClass);
			currentClass = colour;
			progressBar.addClass(currentClass);
		}	

		return { set: set }
	}($('.progress',rootElement));

	//Logger functions - only shown if DOM element is present.
	var logger = function (selector) {
		var add = function ( newStatus ) {
      if (selector == undefined ) return;
			return $(selector).append('<div>' + newStatus + '</div>');
		};
		var info = function ( newStatus ) {
			return add ( newStatus );
		};
		var error = function ( newStatus ) {
			return add ( newStatus );
		};
		var clear = function () {
			$(selector).empty();
		};

		return { add: add, info: info, error: error, clear: clear };
	}($('[data-finity-upload-status]',rootElement)[0]);

  var parse = function (file) {

    //The user didn't pick a file.
    if (file == undefined)
      return;

    data = { all: [], valid: [], invalid: [] };
    logger.clear ();

    //Start the parse process in a separate thread.
    parser = new Worker('/assets/parser.js');
    parser.postMessage( { file: file, CSRFToken: Finity.CSRFToken(), parser: parserType }); 

    //Manage the parser.
    parser.onmessage = function (e) {

      switch (e.data.state) {
        case 'error':
          Finity.dialog.message(e.data.message,"Error")
          logger.clear();
          logger.error("There was an error running the uploader.");
          parser.terminate();
          if ( onParse ) onParse ();
        break;

        case 'in_progress':
          logger.clear ();
          logger.info ( "<strong>" + e.data.message + "</strong>" );
        break;

        case 'progress_position':
          progress.set(e.data.current/e.data.max);
        break;

        case 'complete':
          parser.terminate();
          data = {};
          data.all = e.data.data;
          data.valid = data.all.filter ( function(i) { return i.errors == undefined } );
          data.invalid = data.all.filter ( function (i) { return i.errors != undefined } );

          var qualityRatio = data.valid.length/data.all.length;
          var qualityColour;
          if (qualityRatio <= 0.3)
            qualityColour = 'bg-danger';
          else if (qualityRatio < 1) 
            qualityColour = 'bg-warning';
          else
            qualityColour = 'bg-success';
          
          var qualityText = "Valid: " + data.valid.length + " / Invalid: " + data.invalid.length;
          progress.set(1,qualityText,qualityColour)

          if (qualityRatio == 0) {
            Finity.dialog.message("None of the file's lines are valid to upload. The file may be not in the right structure for FINITy to understand it, or you may have chosen the wrong file.", "All Data Invalid")
          }

          logger.clear();
          if (data.invalid.length == 0 ) {
            logger.add("<strong>Successfully parsed, ready for upload.</strong>");
          }

          //Show the error'ed lines in a human readable list. 
          else {

            var errorList = {};
            for (var x = 0; x < data.all.length; x++) {
              var currentItem = data.all[x];

              if (currentItem.errors == undefined)
                continue;

              for (var y = 0; y < currentItem.errors.length; y++) {
                var currentError = currentItem.errors[y];
                if (errorList[currentError] == undefined)
                  errorList[currentError] = [x];
                else
                  errorList[currentError].push(x);
              }
            };

            {
              let statusString = "";
              if (Object.keys(errorList).length < MAX_ERRORS_TO_SHOW)
                statusString = "<strong>Parsed with " + Object.keys(errorList).length + " errors:</strong>";
              else
                statusString = "<strong>Parsed with " + Object.keys(errorList).length + "+ errors (" + MAX_ERRORS_TO_SHOW + " shown):</strong>";
            }

            var errorCount = 0;
            for (var errorMsg in errorList) {
              errorCount++;

              //Don't show more than 20 errors, it'll confuse users, and that many errors is bound to be an invalid file.
              if (errorCount>MAX_ERRORS_TO_SHOW)
                break;

              //Show line numbers for errors where less than 10 lines have the issue, to aid users to pinpoint "one off" errors.
              if ( errorList[errorMsg].length >= MAX_LINE_NUMBERS_TO_SHOW )
                logger.add(errorMsg + " [x" + errorList[errorMsg].length + "]");
              else {
                var lineNos = "";
                for (var lineNo in errorList[errorMsg]) {
                  //We have to offset the line (row) numbers by 2 - one for the zero-based index, a second time for the header row. Remember it was originally an Excel.
                  lineNos = lineNos + (errorList[errorMsg][lineNo]+2) + ", ";
                }

                //Take out the trailing comma.
                lineNos = lineNos.substr(0,lineNos.length - 2);

                if (errorList[errorMsg].length == 1)
                  logger.add(errorMsg + ": row " + lineNos);
                else
                  logger.add(errorMsg + " [x" + errorList[errorMsg].length + "]: rows " + lineNos);
              }
            }
          }

          if ( onParse ) onParse ();
        break;
      };
    };
  };

  //Send the items to the server, optionally passing some extra JSON. Processes in batches.
  var upload = function (url, extraPayloadData,onComplete) {
		logger.clear();
		logger.add('Submitting data to server...');

    var getNext = function (x) {

      if ( x > data.valid.length ) {
        onComplete();
        return;
      }

      var dataPiece = data.valid.slice ( x, x + UPLOAD_SEGMENT_SIZE );
      progress.set(x/data.valid.length,x + "/" + data.valid.length);

      //Combine our data items with the extra payload that may have been passed (e.g., for an Upload ID).
      var jsonToSend = { data: dataPiece };
      Object.assign(jsonToSend, extraPayloadData);

      Finity.fetch(url,
        { method: 'POST',
          body: JSON.stringify(jsonToSend)
        }).then(function nextStep(response) {
          if ( response.success ) {
            getNext(x + UPLOAD_SEGMENT_SIZE)
          }
          else {
            Finity.dialog.message("FINITy found an issue during upload:<p>" + response.error + "</p>", "Upload Error");
            logger.clear();
            logger.error("Validation errors on upload.");
          }
        });
    };

    //Kicks off the promise chain.
    getNext(0);
  };

  returnUploader.parse = parse;
  returnUploader.upload = upload;
  returnUploader.data = function () {
    return data;
  };

  return returnUploader;
};

export default Finity;
