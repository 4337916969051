//Masterdata upload dialog.
import Finity from 'finity'

$(document).on('turbolinks:load', function startUi () {

  const FINITY_TABLEAU_EMBED_URL = 'https://tableau.siemens-energy.cloud/t/KlarIT/views/'

  //Initiate Tableau embeds.
  $('[data-finity-tableau-embed]').map ( function (x, el) {
    var siteUrl = FINITY_TABLEAU_EMBED_URL + $(el).data("finity-tableau-embed");
    var setupViz = function (e) {

      //Set up the sheet selection dropdowns.
      var dropdown = $('#tableau-selector');
      if ( dropdown != undefined ) {
        $(dropdown).empty();
        var sheets = viz.getWorkbook().getPublishedSheetsInfo();
        for ( var x = 0; x < sheets.length; x++ ) {
          var currentSheet = sheets[x];
          var newOption = $("<option>" + currentSheet.getName () + "</option>");
          newOption.prop('value', currentSheet.getName());
          $(dropdown).append(newOption);
        }

        //Now that the list is populated, enable the dropdown.
        $(dropdown).prop('disabled',false);

        //Every time a new sheet is selected on the dropdown, change the sheet we're on.
        $(dropdown).on('change', function changeSheet(e) {
            $(dropdown).prop('disabled',true);
          viz.getWorkbook().activateSheetAsync(e.target.value).always(function(p) {
            $(dropdown).prop('disabled',false);
          });
        });

        //Also update the dropdown if another sheet is browsed to by the Tableau visualisation itself.
        viz.addEventListener(tableau.TableauEventName.TAB_SWITCH, function(e) {
          $('option',dropdown).map(function(i, optionEl) {
            $(optionEl).attr('selected', e.getNewSheetName () == $(optionEl).attr('value'))
          });
        });

        //As this is the first load, make sure we select the right tab - it may be the Tableau visualisation is opened on a different tab from the first.
        $('option',dropdown).map(function(i, optionEl) {
          $(optionEl).attr('selected', viz.getWorkbook ().getActiveSheet().getName() == $(optionEl).attr('value'))
        });
        
      }

      //Setup the download option.
      var download = $('#tableau-download');
      if ( download != undefined )
      {
        $(download).removeClass('disabled');
        $(download).on('click',function(e) {
          e.preventDefault();
          viz.showExportDataDialog();
        });
      }
    };

    var viz = new tableau.Viz(el, siteUrl, {hideTabs: true, hideToolbar: true, device: 'desktop', onFirstInteractive: setupViz});

  });

});
