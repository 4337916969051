//Masterdata upload dialog.
import Finity from 'finity'
import XLSX from 'xlsx'

$(document).on('turbolinks:load', function startUi () {

  const MAX_RETRIES = 3;

  //Enable Excel downloads. They may optionally be paginated, in which case we may make multiple round trips.
  $('[data-finity-create-excel]').each ( function () {
    var button = this;

    $(button).on('click', function (e) {
      e.preventDefault ();

      $(button).prop('disabled',true);
      $(button).addClass('disabled');
      var origContent = $(button).html();
      $(button).html('<div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Downloading...</span></div> Downloading...');

      var fetchPath = $(button).data('finity-create-excel');

      var allData = [];
      var retries = 0;
      var currentPage = 0;

      var errorHandler = function ( error ) {
        retries = retries + 1;

        if ( retries > MAX_RETRIES ) {
          Finity.dialog.message("Unable to download: HTTP status " + error.status + " (" + error.statusText + "). Retried " + (retries-1) + " times.","Download Error");
          $(button).prop('disabled', false);
          $(button).removeClass('disabled');
          $(button).html(origContent);
          return;
        }

        if (fetchPath.includes("?"))
          Finity.fetch(fetchPath + "&page=" + currentPage, { method: 'GET' }).then(processResponse, errorHandler);
        else
          Finity.fetch(fetchPath + "?page=" + currentPage, { method: 'GET' }).then(processResponse, errorHandler);

      };

      var processResponse = function ( response ) {

        var allPagesDownloaded = false;
        if (response.paginated != undefined) {
          allPagesDownloaded = (response.paginated.current == response.paginated.maximum);
          allData = allData.concat(response.paginated.data);
        }
        // If no pagination response, there was only one page.
        else {
          allPagesDownloaded = true;
          allData = response;
        }

        // When the complete response is given it will be in 'allData' so we can create the Excel file.
        if (allPagesDownloaded) { 

          var workbook = XLSX.utils.book_new();
          var worksheet = XLSX.utils.json_to_sheet(allData);

          XLSX.utils.book_append_sheet(workbook, worksheet, 'FINITy Export');

          //Create a nice file path.
          var currentDate = new Date ( Date.now() );
          var filePath = currentDate.toISOString ().slice(0,10) + ' - FINITy ' + document.title.replace(" - FINITy", "") + '.xlsx';

          XLSX.writeFile (workbook, filePath);

          $(button).prop('disabled',false);
          $(button).removeClass('disabled');
          $(button).html(origContent);
        }
        // Fetch another page.
        else {
          currentPage = parseInt (response.paginated.current)+1;
          $(button).html('<div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Downloading...</span></div> Downloading ' + Math.round((currentPage-1)/response.paginated.maximum*100,0) + '%...');
        if (fetchPath.includes("?"))
          Finity.fetch(fetchPath + "&page=" + currentPage, { method: 'GET' }).then(processResponse, errorHandler);
        else
          Finity.fetch(fetchPath + "?page=" + currentPage, { method: 'GET' }).then(processResponse, errorHandler);
        }

      };
      Finity.fetch(fetchPath, { method: 'GET' }).then( processResponse, errorHandler );

    });
  } );

});
