import Finity from 'finity'
import * as Bootstrap from 'bootstrap'

$(document).on('turbolinks:load', function startUi () {

  //Enable bootstrap tooltips.
  $('#target_form input').on ( 'input', function ( e ) {

    $('#' + $(e.currentTarget).data('tooltip-id')).html (
      '\u20AC' + Number($(e.currentTarget).val ()).toLocaleString()
    );
    
  } );

});

