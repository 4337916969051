import Finity from 'finity'
import * as Bootstrap from 'bootstrap'

$(document).on('turbolinks:load', function startUi () {

  if ($('[data-finity-subform]').length == 0)
  return;

  var table = $('[data-finity-subform=table]')[0];
  var template = $('[data-finity-subform=template] tbody tr')[0];
  var dummyID = parseInt($(template).find('input:first').prop('name').match(/([0-9]+)/)[0]);

  //Remove the template from the DOM. It stays in memory though.
  $(template).closest('table').remove();
  $(template).removeClass('d-none');

  if (table == null || template == null) {
    console.log("Error making subform: table/template not found");
  }

  if (dummyID == null) {
    console.log("Error making subform: unknown dummy ID");
  }
  
  var addRow = function(e) {
    e.preventDefault ();

    //Auto-increment the name ID so the form submits properly.
    var nextID = dummyID + $(table).find('tbody tr').length + 1;
    console.log("NextID: " + nextID);

    var new_row = $(template).clone().appendTo(table);
    new_row.data('finity-subform', null);

    new_row.find('select,input').each(function(index, cur) {
      var el = $(cur);
      el.prop('name', el.prop('name').replace(dummyID, nextID));
      el.prop('id', el.prop('id').replace(dummyID, nextID));
    });

    // Make sure this new row also listens for the right events.
    new_row.find('[data-finity-subform=delete-row]').on('click', deleteRow);

    enableSubmitButton();
  };
  
  var deleteRow = function (e) {
    e.preventDefault();

    var row = $(e.target).closest('tr');

    //If the item already exists, it'll have an ID and we will have to POST the form with the __destroy key to remove it.
    //Otherwise, we can remove it right away.
    //We test this by looking for a hidden form element with 'id' at the end of its name.
    if ( $(row).find('input[type=hidden][id$=id]').length == 0 ) {
      $(row).remove();
      return;
    }

    var marked_for_deletion = $(row).find('input[type=hidden][id$=__destroy]').prop('value');
   
    if (marked_for_deletion == 'false') {
      marked_for_deletion = true;
    }
    else if (marked_for_deletion == 'true') {
      marked_for_deletion = false;
    }
    
    $(row).find('input[type=hidden][id$=__destroy').prop('value',marked_for_deletion);

    if (marked_for_deletion) {
      $(row).addClass('table-danger');
      $(e.target).closest('a').removeClass('btn-outline-danger');
      $(e.target).closest('a').addClass('btn-danger');
    }
    else {
      $(row).removeClass('table-danger');
      $(e.target).closest('a').removeClass('btn-danger');
      $(e.target).closest('a').addClass('btn-outline-danger');
    }

    enableSubmitButton();
  };

  var submit_button = $('[data-finity-subform=submit]');
  var enableSubmitButton;
  if (submit_button == null) {
    enableSubmitButton = function () { };
    return;
  }
  else {
    enableSubmitButton = function () {
      submit_button.prop('disabled', null);
    };
    $(table).find('input,select').on('change', enableSubmitButton);
  }

  //Ensure any existing DOM elements have the right event handlers added.
  $('[data-finity-subform=add-row]').on('click', addRow );
  $('[data-finity-subform=delete-row]').on('click', deleteRow);

});
