import Finity from 'finity'
import * as Bootstrap from 'bootstrap'

//Override the default unobtrusive js confirmation dialog with our own modal.
$.rails.confirm = function () {
  var trustedEl = null;

  return function (message, element) {
    if ( trustedEl == element )
      return true;

    Finity.dialog.confirm(message, 'Are you sure?', 'OK', function onConfirm () {
      //Retrigger the same event. Now that trustedEl is set, it will immediately confirm.
      trustedEl = element;
      element.click();
    });
  };
}();

$(document).on('turbolinks:load', function startUi () {

  //Enable bootstrap tooltips.
  $('[data-bs-toggle="tooltip"]').map ( function ( x, tooltipEl ) {
    return new Bootstrap.Tooltip(tooltipEl);
  } );

  //Shows a countdown timer on the user session.
  var setupLogoutTimer = function () {
    var logoutTimer = $('#logout-timer');
    if ( logoutTimer == null ) {
      return;
    }

    var logoutTime = Date.now() + (logoutTimer.data('logout-timer') * 1000);
    var updateClock = function () {
      var timeRemaining = new Date ( Math.max(logoutTime - Date.now(), 0) );

      if ( (timeRemaining.getHours()-1) == 0 )
        logoutTimer.html ( timeRemaining.getMinutes () + ":" + timeRemaining.getSeconds() );
      else
        logoutTimer.html ( (timeRemaining.getHours ()-1) + ":" + timeRemaining.getMinutes () + ":" + timeRemaining.getSeconds() );
    }
    updateClock();
    setInterval ( updateClock, 250 );
  }();

  //Setup the autofilter form to automatically submit on any change.
  $('#autofilters').map ( function (x, el) {
    $(el).on('change', function (e) {
      e.currentTarget.submit();
    });
  });

  //Initiate the main uploader form.
  $('#uploadForm').map ( function (x, el) {

    //Enable the upload button when the upload is in a valid state.
    var updateButton = function () {
      var isEnabled = true;
      
      if ( $('input[type=text]',el)[0].value.length < 3 ) { isEnabled = false; }
      if ( uploader.data().valid.length == 0 ) { isEnabled = false; }

      if ( isEnabled ) { 
        $('button',el).removeAttr('disabled');
      }
      else {
        $('button',el).attr('disabled','disabled');
      }
    };
    $('#uploadForm input[type=text]').on('input', updateButton);

    //Set up the uploader.
    var uploader = Finity.uploader(el, 'parsers.upload', updateButton);
    var ignoreBigWarning = false;

    //Trigger a parse whenever the file is changed.
    $('input[type=file]',el).on('change', function (e) {
      var file = e.target.files[0];

      //Warn on files larger than 10MB.
      var fileSizeInMB = file.size/1024/1024;
      if (fileSizeInMB > 10 && ignoreBigWarning == false) {
        Finity.dialog.confirm("This is a large file (" + Math.round(fileSizeInMB) + " MB) and your browser may crash if you try to process it, losing work in other tabs. Are you sure?", "Large file", "Continue processing",
      function () {
          ignoreBigWarning = true;
          $('input[type=file]').trigger('change');
      });
        return;
      }

      uploader.parse(file);
    });

    //Trigger the actual item uploads.
    var ignoreQualityWarning = false;
    $(el).on('submit', function (e) {
      e.preventDefault();

      var qualityRatio = uploader.data().valid.length/(uploader.data().valid.length + uploader.data().invalid.length);

      if ( qualityRatio < 1 && ignoreQualityWarning == false ) {
        Finity.dialog.confirm("This file has " + uploader.data().invalid.length + " invalid lines (" + Math.round((1-qualityRatio)*100,0) + "%). Are you sure you want to upload just the " + uploader.data().valid.length + " valid lines?","Invalid Lines","Continue upload",
        function skipWarning () {
          ignoreQualityWarning = true;
          $(el).trigger('submit');
        });
        return;
      };

      //Reset the quality warning so that future uploads on the same page will get checked the same way.
      ignoreQualityWarning = false;

      //Create the upload record.
      Finity.fetch('/uploads',
        { method: 'POST',
          body: JSON.stringify({ name: $('input[type=text]',el)[0].value })
        }
        ).then (function ( response ) {
          uploader.upload('/line_items/upload', { upload_id: response.id }, function () {
            window.location = response.redirect_to;
            return;
          });
      });

      });
  });

});

