//Masterdata upload dialog.
import Finity from 'finity'

$(document).on('turbolinks:load', function startUi () {

  $('[data-finity-masterdata-upload]').map ( function (x, button) {
    var modal;
    var uploader;
    var controller = $(button).data('finity-masterdata-upload');
    var niceName = (controller.charAt(0).toUpperCase () + controller.slice(1)).replaceAll("_"," ");

    var onParsed = function () {
      var no_all = uploader.data().all.length;
      var no_new = uploader.data().all.filter ( function(c) { return c.id === undefined } ).length;
      var no_mod = uploader.data().all.filter ( function(c) { return c.id != undefined && c.delete != 'yes' } ).length;
      var no_del = uploader.data().all.filter ( function(c) { return c.delete == 'yes' && c.id != undefined } ).length;

      modal.buttons[0].prop('disabled', no_all == 0);

      $('[data-finity-upload-status]',modal.body).html("<strong>Ready to upload " + no_all + " items:</strong> <span class='text-success'>" + no_new + " new</span>, <span class='text-warning'>" + no_mod + " edits</span>, <span class='text-danger'>" + no_del + " deletes</span>" );
    };

    //We don't use the uploader's batching functionality for master data uploads. Instead, we send everything at once.
    var origButton = $(button).html();
    var onSubmit = function () {
      $(button).addClass ( 'disabled' );
      $(button).html('<div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Updating...</span></div> Updating...');

      //Add the parent ID if present.
      var dataPayload = { data: uploader.data().all };
      var parent_id = $(button).data('finity-masterdata-upload-parent-id');
      if (parent_id != undefined) {
        dataPayload.parent_id = parent_id;
      }

      Finity.fetch('/' + controller + '/upload', { method: 'POST', body: JSON.stringify ( dataPayload ) })
      .then(function (response) {
        if ( response.success == true )
          window.location = response.redirect_to;
        else
          Finity.dialog.message("<p>" + response.error + "</p><span class='text-danger'>No changes were made to any data.</span>","Bulk Update Error");
      })
      .catch(function (error) {

          switch(error.status) {
            
            case 413:
              Finity.dialog.message("The file is too large for FINITy to process. Please split it.", "File too Large");
            break;

            case 504:
              Finity.dialog.message("FINITy is still processing, but ran out of time to respond. Please wait a while before uploading again as it'll probably still complete.", "Still Processing");
            break;

            default:
              Finity.dialog.message("An internal error occured, please report this: <strong>" + error.status + ": " + error.statusText + "</strong>", "Internal Error");

          }

      })
      .finally(function () {
          $(button).removeClass('disabled');
          $(button).html(origButton);
      });
    };

    $(button).on('click', function (e) {
      e.preventDefault ();

      modal = Finity.dialog.fileInput("Select an Excel file in the same format as the download. Rows:<ul><li>with an ID will be updated</li><li>without an ID will be created</li><li>with an ID and 'yes' in a column named 'delete' will be deleted</li></ul><strong class='text-danger'>Caution: changes cannot be reversed.</strong><p data-finity-upload-status='1'></p>",
       "Upload " + niceName,
       "Upload",
       onSubmit);
      modal.buttons[0].prop('disabled',true);

      uploader = Finity.uploader(modal.domModal,'parsers.masterdata',onParsed);

      $('input[type=file]')[0].name = 'masterdata-upload';
      $('input[type=file]',modal.domModal).on('change', function (e) {
        var file = e.target.files[0];
        uploader.parse(file);
      });
    });
  });

});

